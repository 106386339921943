// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-konfigurator-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/konfigurator.tsx" /* webpackChunkName: "component---src-templates-konfigurator-tsx" */),
  "component---src-templates-konfigurator-potwierdzenie-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/konfigurator-potwierdzenie.tsx" /* webpackChunkName: "component---src-templates-konfigurator-potwierdzenie-tsx" */),
  "component---src-templates-aktualnosci-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/aktualnosci.tsx" /* webpackChunkName: "component---src-templates-aktualnosci-tsx" */),
  "component---src-templates-demo-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/demo.tsx" /* webpackChunkName: "component---src-templates-demo-tsx" */),
  "component---src-templates-dofinansowanie-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/dofinansowanie.tsx" /* webpackChunkName: "component---src-templates-dofinansowanie-tsx" */),
  "component---src-templates-demo-potwierdzenie-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/demo-potwierdzenie.tsx" /* webpackChunkName: "component---src-templates-demo-potwierdzenie-tsx" */),
  "component---src-templates-o-nas-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/o-nas.tsx" /* webpackChunkName: "component---src-templates-o-nas-tsx" */),
  "component---src-templates-inter-cars-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/inter-cars.tsx" /* webpackChunkName: "component---src-templates-inter-cars-tsx" */),
  "component---src-templates-akcesoria-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/akcesoria.tsx" /* webpackChunkName: "component---src-templates-akcesoria-tsx" */),
  "component---src-templates-branze-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/branze.tsx" /* webpackChunkName: "component---src-templates-branze-tsx" */),
  "component---src-templates-produkt-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/produkt.tsx" /* webpackChunkName: "component---src-templates-produkt-tsx" */),
  "component---src-templates-cennik-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/cennik.tsx" /* webpackChunkName: "component---src-templates-cennik-tsx" */),
  "component---src-templates-kasa-fiskalna-vs-kasoterminal-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/kasa-fiskalna-vs-kasoterminal.tsx" /* webpackChunkName: "component---src-templates-kasa-fiskalna-vs-kasoterminal-tsx" */),
  "component---src-templates-franczyzy-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/franczyzy.tsx" /* webpackChunkName: "component---src-templates-franczyzy-tsx" */),
  "component---src-templates-artykul-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/artykul.tsx" /* webpackChunkName: "component---src-templates-artykul-tsx" */),
  "component---src-templates-branze-branza-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/branze/branza.tsx" /* webpackChunkName: "component---src-templates-branze-branza-tsx" */),
  "component---src-templates-pages-simple-page-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/templates/pages/simplePage.tsx" /* webpackChunkName: "component---src-templates-pages-simple-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/vmlyr-dev/ipos_pl/front/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

